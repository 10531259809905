export default {
    init() {
        jQuery('.main-video-wrapper .close').click(function () {
            jQuery('.main-video-wrapper').removeClass('visible')
        })

        jQuery('.main-video-wrapper').click(function (event) {
            if (event.target && $(event.target).hasClass('main-video-wrapper')) {
                jQuery('.main-video-wrapper').removeClass('visible')
            }
        })
    },
    finalize() {
        /*
    setTimeout(
        function () {
            var popupShown = Cookies.get('soa-video');

            if (!popupShown) {
                Cookies.set('soa-video', true, { expires: 15 });
                jQuery('.main-video-wrapper').addClass('visible');
            }
        },
        2000
    );
    */
    }
}
