export default {
    init() {
        // JavaScript to be fired on all pages
        jQuery('.toggle-mobile-menu').click(function () {
            jQuery('body').toggleClass('menu-visible')
        })

        jQuery('.close-feedback').click(function () {
            jQuery('body').removeClass('feedback-visible')
        })

        jQuery('#feedback').click(function (event) {
            if (event.target && jQuery(event.target).hasClass('feedback-wrapper')) {
                jQuery('body').removeClass('feedback-visible')
            }
        })

        jQuery('.leave-comment').click(function () {
            jQuery('body').addClass('feedback-visible')
        })
    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    }
}
