export default {
    init() {
        /**
         * Post page toggle infos
         */
        jQuery('.toggleData').each(function () {
            var event = jQuery(this).data('event')
            var author = jQuery(this).data('author')
            var post = jQuery(this).data('post')

            jQuery(this).click(function () {
                console.log(event, author, post, Date.now())
            })
        })
    }
}
